@import (reference) "main.less";

/* Responsive Adaptions */
@media screen and (min-width: 768px) {
    #personDetailStaticContent .educationRatingStudentDetailButtonBar .defaultButtonContainer {
        margin-left: initial;
    }

    #personDetailStaticContent .educationRatingStudentDetailButtonBar .personDetailEducationcardButton {
        margin-left: auto;
        margin-right: 10px;
    }

    #easyAccounting table {
        max-width: calc(50% - 6px);
        min-width: calc(50% - 6px);
        margin-bottom: @default-padding-value;
        padding-left: 3px;
    }
}

@media screen and (min-width: 991px) {
    .searchHeaderAllStudents.searchBillsView {
        min-width: 500px;
    }
}

@media screen and (max-width: 640px) {
    .footer-button-text {
        font-size: 13px;
    }
    .btn {
        padding: 6px 12px;
        font-size: 12px;
        height: auto;
    }

    .sleepingSmileyButton.iconOnly i {
        font-size: 57px;
    }

    .buttonContainer {
        padding: 10px 15px 10px 0;
    }

    body .invoicesNameCell {
        max-width: 100px;
    }

    body #popupEndLesson .default-padding {
        padding: 0;
    }

    body #billTablePaperwork {
        font-size: @small-font-size;
    }

    body .gridTable .tableCell {
        padding: 10px 5px 10px 5px;
        font-size: 14px;
    }

    .listFooterRow .tableCell {
        padding: 10px 5px 10px 5px;
    }

    #billListPaperworkBills.gridTable .tableCell,
    #billTablePaperworkHeader.gridTable .tableCell {
        font-size: 13px;
    }

    #billListPaperworkBills .billStatusText {
        max-width: 50px;
    }

    .billsPaperworkState {
        min-width: initial;
    }

    body .card .titleRow {
        font-size: 24px;
    }

    body .card .additional {
        padding-left: 7px;
        margin-left: 7px;
    }

    body .card .cardNumber {
        line-height: 1;
    }

    body .billsPaperworkSumCell {
        min-width: 65px;
    }

    .searchHeaderAllStudents.searchBillsView {
        max-width: 175px;
    }

    .responsive-flex {
        display: block;
    }

    #subscriptionsStateTitle {
        width: 225px;
    }

    .allPersonsNoPersons img {
        max-width: 150px;
    }

    .listTable .km-list > li > .tableCell {
        height: 50px;
    }

    .contentEducationPaper {
        padding-top: 15px;
        padding-bottom: 5px;
    }

    .sellSubscriptions.noMargin {
        margin-top: 10px;
    }

    .sellSubscriptions .defaultButtonContainer {
        margin-top: 10px;
    }

    .introductionPhoto .veryLargeTitle {
        font-size: 64px;
    }

    .drawing-toolbar-button {
        font-size: 12px;
        padding: 5px 6px;
    }

    .drawing-toolbar-button i {
        font-size: 16px;
    }

    .drawing-color-button {
        padding-top: 12px;
    }

    #pagerPaperworkBills,
    #pagerPaperworkTimeTracking {
        text-align: center;
    }

    #listViewCompletedDrivingLessons {
        font-size: 15px;
    }

    #subscriptionOverviewStudentDetailScroller .tableRow {
        display: block;
        border-bottom: 1px solid white;
        padding-bottom: 3px;
        padding-top: 7px;
    }

    #subscriptionOverviewStudentDetailScroller .tableRow .tableCell {
        display: inline-block;
        width: 24%;
        min-width: 24%;
        border-bottom: none;
        height: auto;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    #subscriptionOverviewStudentDetailScroller .tableRow .subscriptionsBoughtTitle {
        min-width: 49%;
    }

    #subscriptionOverviewStudentDetailScroller .tableRow .subscriptionsStateTitle {
        min-width: 100%;
    }

    #subscriptionOverviewStudentDetailScroller .billStatusSymbol {
        margin-left: -5px;
    }
}
@media screen and (max-width: 354px) {
    .overviewButtons.default-flex {
        justify-content: flex-start;
        flex-wrap: wrap;
    }
}

/* XS */
@media screen and (max-width: 480px) {
    .allPersonTemplate .allpersonName {
        min-width: 69%;
        max-width: 69%;
        line-height: 42px;
    }
    .iconBtnLarge.iconOnly i {
        margin: -1px 0 0 -2px;
    }
    #ECCMasterElement .iconBtnLarge.iconOnly i {
        font-size: 24px;
    }

    .iconBtnLarge.iconOnly {
        height: 42px;
        width: 42px;
        min-width: 42px;
        min-height: 42px;
    }

    .mySubscription {
        font-size: small;
    }

    .subscriptionBillInfo {
        min-width: 70px;
    }

    .inputFieldContainer.educationSwitch {
        max-width: 180px;
    }

    .productsPriceInput .k-widget.k-numerictextbox.inputField.k-input {
        height: unset;
    }

    /*Fix Table horizontal scroll personDetail subscriptions*/
    #subscriptionOverviewStudentDetailHeader div:first-child {
        display: flex;
    }

    /*remove icon from choosen lesson in plan lesson*/
    .planLessonContainerRight .lessonItemInner:after {
        display: none;
    }

    #productListProductManagerScroller {
        padding-left: 10px;
        padding-right: 10px;
    }

    #listElementAllPersons .iconOnly {
        margin-bottom: 5px;
    }

    .drawing-toolbar {
        flex-wrap: wrap;
    }

    .wasInPlannedLesson {
        background: transparent;
    }

    #listViewCompletedDrivingLessons {
        font-size: 12px;
    }

    .getLessonCountCell {
        min-width: initial;
    }

    .getLessonCountCellButtons {
        margin-left: 0;
    }

    .getLessonCountCellButtons .iconOnly {
        display: block;
    }

    .billStatusText.smallText {
        font-size: 11px;
    }

    /* Bills Table */
    .km-flat .table.km-listview > li {
        display: block;
        margin-left: 0;
        margin-right: 0;
    }

    .table.km-listview .tableCell {
        display: inline-block;
        width: 23.5%;
        vertical-align: top;
        box-sizing: border-box;
    }

    .table.km-listview .billsPaperworkID {
        width: 65px;
        font-size: 13px;
    }

    .table.km-listview .billTrashTableCell,
    .table.km-listview .billsPaperworkState {
        width: auto;
    }

    .table.km-listview .invoicesNameCell {
        max-width: initial;
        width: calc(~"50% - 70px");
    }

    /* Bills Table End */
    .drawing-toolbar-button {
        width: 20%;
    }

    .drawing-button-delete-last,
    .drawing-button-delete-all {
        width: 30%;
    }

    #drawingMasterElement .default-flex {
        justify-content: space-between;
    }

    .searchHeaderAllStudents.searchBillsView {
        max-width: initial;
    }

    .drawing-button-freehand,
    .drawing-button-line {
        border-right: none;
        width: 100%;
        padding-top: 13px;
        padding-bottom: 13px;
    }

    #educationPaper .lessonItemInner {
        padding: 0;
    }

    .listTable .km-list > li > .tableCell {
        height: 50px;
    }

    body.km-flat header + .km-content {
        padding-top: 15px;
    }

    .km-flat .listFooterRow .km-list > li {
        padding-left: 15px;
        padding-right: 15px;
    }

    .km-flat .km-list > li {
        margin-left: 15px;
        margin-right: 15px;
    }

    .startLessonWrappers button {
        margin: 10px 0;
    }

    .planLessonContainer .km-list {
        padding-left: 0;
        padding-right: 0;
    }

    .planLessonContainer {
        width: calc(~"50% - 15px");
    }

    .km-root .km-flat.km-pane,
    .km-root .km-flat .km-view {
        font-size: 15px;
    }

    input,
    textarea,
    keygen,
    select,
    button {
        font-size: 15px;
    }

    .globalFooterButtons {
        font-size: 11px;
    }

    .introductionPhoto img {
        max-width: 60%;
    }

    .largeTitle {
        font-size: 22px;
    }

    .introductionPhoto .veryLargeTitle {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .viewFirstTimeUsageInner .welcome {
        font-size: 43px;
        margin-top: 5px;
    }

    #firstTimeUsageView .configurationDoneHeader {
        font-size: @small-font-size;
    }

    .firstTimeIntroductionNextButton i {
        font-size: 40px;
        width: 40px;
        height: 40px;
    }

    #headerTitleFTI .km-pages li {
        margin: 3px 10px;
    }

    .km-widget .default-padding {
        padding-left: 15px;
        padding-right: 15px;
    }

    .cards #cardsLeft {
        padding-right: 15px;
        margin-bottom: 20px;
    }

    .workingHourCard {
        margin-bottom: 0px;
    }

    .cards #cardsRight {
        padding-left: 15px;
    }

    #login {
        margin-left: 15px;
        margin-right: 15px;
    }

    .inputFieldContainer input,
    .k-dropdown-wrap .k-input,
    .inputFieldContainer .k-numerictextbox .k-numeric-wrap input,
    .inputFieldContainerTextarea.inputFieldContainer textarea {
        font-size: @default-font-size;
    }

    .inputFieldContainer .k-dropdown-wrap.k-state-default {
        min-height: 25px;
    }

    .k-dropdown .k-i-arrow-60-down:before {
        font-size: 25px;
    }

    .inputFieldContainer {
        height: auto;
        margin-bottom: 15px;
        padding: 5px 13px 3px 13px;
    }

    .km-view .high-margin {
        margin-bottom: 30px;
    }

    .mediumText-responsive {
        font-size: @default-font-size;
    }

    .smallText-responsive {
        font-size: @small-font-size;
    }

    .gridTable {
        margin-left: 15px;
        margin-right: 15px;
        width: calc(~"100% - 30px");
        overflow: visible;
    }

    .table.responsiveTable {
        display: block;
    }

    .table.responsiveTable .tableRow,
    .table.responsiveTable.gridTable .tableCell {
        height: auto;
        display: block;
        width: 100%;
        max-width: none;
        padding-left: 0;
        padding-right: 0;
    }

    .responsiveTable .tableCell:first-child {
        font-weight: 700;
        font-size: 120%;
    }

    .responsiveTable .tableRow:last-child .tableCell {
        border-bottom: 1px solid white;
    }

    .hidden-xs + .white-divider {
        margin-bottom: 0;
    }

    #billTablePaperwork .tableCell {
        height: auto;
    }

    #controlHeaderAllStudents > .default-flex {
        flex-wrap: wrap;
    }

    #controlHeaderAllStudents > .default-flex .iconOnly {
        margin-left: auto;
    }

    .searchHeaderAllStudents {
        order: 4;
        width: 100%;
        margin-top: 10px;
    }

    .km-on-ios .searchInputField {
        width: 100%;
    }

    .wlan-info {
        padding-left: 0;
    }

    .lessonSideBar {
        width: 200px;
        margin-left: -200px;
    }

    .lessonItemInner {
        padding: 8px 0;
        font-size: @small-font-size;
    }

    #plannedLessonsPlanLesson .lessonItemInner {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .startLessonWrappers.default-padding {
        padding-left: 0;
    }

    .startLessonWrappers .btn {
        white-space: normal;
        text-align: center;
    }

    .startLessonWrappers .btn i {
        display: none;
    }

    .startLessonWrappers .btn .btnText {
        margin-left: 0;
    }

    #cardTimeTrackings .card,
    #cardsRightPaperworkDashboard .card,
    #cardsRightPaperworkDashboard .card {
        height: auto;
        max-height: 600px;
    }

    .cards #cardsLeftPaperworkDashboard,
    .cards #cardsRightPaperworkDashboard {
        padding-right: 15px;
        padding-left: 15px;
    }

    .cards #cardsLeftPaperworkDashboard {
        margin-bottom: 20px;
    }

    .personDetailHeaderStudentDetail {
        padding-left: 15px;
        padding-right: 15px;
    }

    .personDetailHeader {
        padding-bottom: 8px;
    }

    .detailHeaderContactInfos {
        margin: 0 15px 0 15px;
    }

    .personDetailAvatar {
        width: 40px;
        height: 40px;
        font-size: 16px;
        margin-right: 7px;
    }

    .personDetailNoAvatar {
        .personDetailAvatar;
        margin-right: 0;
        min-width: 40px;
        min-height: 40px;
    }

    .personDetailHeaderStudentDetail .largeTitle {
        font-size: 20px;
    }

    .overviewInfoContainer .personDetailPhoneButton {
        margin-left: 0;
        margin-right: 0;
        margin-top: 5px;
    }

    .personDetailSMSButton {
        margin-top: 5px;
    }

    .km-content .StudentDetailNoteInfo {
        padding-left: 15px;
    }

    .overviewInfoContainer {
        white-space: normal;
    }

    .StudentDetailNoteInfo .overviewInfoContainer:first-child {
        margin-bottom: 15px;
    }

    .headerBackButton .btnText {
        display: none;
    }

    .flex-wrap-responsive {
        flex-wrap: wrap;
    }

    .studentDetailExamCheckboxes > div {
        width: 50%;
        margin-bottom: 15px;
    }

    #contentEducationCard .overviewInfoContainer {
        max-height: none;
    }

    .ratingCircle {
        padding: 0 2px;
    }

    .gridTable div[data-id="educationPaperTitleCell"],
    .gridTable div[data-id="educationPaperSmileyCell"] {
        border-bottom: none;
        padding-bottom: 0;
    }

    #lfaPhotoContainerRegisterStudent {
        margin-left: 0;
        margin-top: 15px;
        max-width: 40%;
    }

    #createBillButton {
        margin-left: 0;
        margin-top: 10px;
    }

    .searchNameforBillResults li {
        line-height: 35px;
        height: 35px;
    }

    #controlHeaderPaperworkBills > .default-inline-flex {
        flex-wrap: wrap;
    }

    .searchHeaderAllStudents.searchBillsView {
        margin-left: 0;
        padding-left: 0;
    }

    .searchNameforBill .searchInputField {
        padding-left: 0;
        padding-right: 0;
    }

    .tabLine {
        white-space: normal;
    }

    .btnTab {
        background: rgba(255, 255, 255, 0.25);
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .tabActive {
        background: rgba(255, 255, 255, 1);
    }

    .examDateStudentDriverButtons {
        padding-left: 20px;
    }

    .syncStatusBarSyncState {
        font-size: @small-font-size;
    }

    .syncStatusBarSyncIcon i {
        font-size: 24px;
    }

    #syncStatusColorMainView {
        flex-wrap: wrap;
        padding: 0;
    }

    #syncStatusTextMainview {
        width: calc(~"100% - 38px");
        padding: 7px;
    }

    .syncStatusBarSyncDate {
        border-left: 0;
        border-top: 1px solid white;
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        width: 100%;
        padding: 7px;
    }

    .syncStatusBarSyncIcon {
        padding: 7px;
        width: 38px;
    }

    .profileNameMainView-inner {
        margin-bottom: 15px;
    }

    .inputFieldContainer .iconOnly i {
        font-size: 24px;
    }

    .inputFieldContainer .iconOnly {
        top: 20px;
    }

    .registerStudentLFAThumb .inputFieldContainer {
        min-width: 10px;
        width: 100%;
    }

    .sleepingSmileyButton {
        margin-top: 10px;
    }

    .k-editor-inline {
        font-size: @default-font-size;
    }

    .k-button {
        line-height: 1.2;
    }

    .productManagerTableIconcell .iconOnly {
        height: 29px;
        width: 29px;
        min-height: 29px;
        min-width: 29px;
        padding: 7px;
    }

    .productManagerTableIconcell .iconOnly i {
        font-size: 16px;
        width: 16px;
    }

    .editingInputFieldContainer {
        padding: 6px 10px;
    }

    .k-numerictextbox.inputField {
        margin-bottom: 0;
        height: 29px;
    }

    .settingsButton {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .settingsButton:before {
        margin-top: 0;
    }

    .planLessonArrow {
        width: 30px;
    }

    .planLessonArrow i {
        font-size: 20px;
    }

    #allLessonsPlanLesson,
    .planLesson .chapterTitleWrapper,
    #plannedLessonsPlanLesson {
        width: calc(~"50% - 15px");
    }

    .k-checkbox-label:before {
        margin-top: 0;
    }

    .firstTimeUsageConfigPage {
        margin-top: 90px;
    }

    #lessonContentEducationPaperInner {
        width: calc(~"100vw - 30px");
    }

    .billLogo .iconOnly i {
        font-size: @default-font-size;
    }

    .billLogo .iconBtnLarge.iconOnly {
        min-height: initial;
        min-width: initial;
        padding: 5px;
    }

    .billLayoutPreviewElement {
        font-size: 12px;
    }

    #chapterTitleExecuteLesson {
        font-size: @default-font-size;
    }

    #lessonWithExecuteLesson {
        padding-right: 7px;
    }

    .infoBoxDesc {
        font-size: @small-font-size;
    }

    .infoBoxTitle.advice {
        font-size: @small-font-size;
    }

    .allPersonsList li .tableCell:first-child {
        font-size: 18px;
    }

    .allPersonsNoPersons img {
        max-width: 100px;
        margin-right: 15px;
    }

    .allPersonsNoPersons .largeNumber {
        font-size: 49px;
    }

    .noPersonsInscructionItem {
        margin-bottom: 10px;
    }

    .graphic-wrapper {
        width: 50%;
    }

    .dashboard-graphics-container > div {
        display: inline-block;
    }

    .listTable .km-list {
        padding-left: 15px;
        padding-right: 15px;
    }

    .km-flat .km-group-title {
        padding-left: 15px;
        padding-right: 15px;
    }

    .confirmContent > .largeTitle {
        font-size: 35px;
    }

    .editingChecklist button.icons {
        font-size: 20px;
    }

    h3 {
        font-size: 20px;
    }

    .radioWrapper label {
        font-size: 15px;
    }

    .faqRow {
        font-size: 18px;
    }

    .questionBoxHelp {
        font-size: 15px;
    }

    .registerPersonActionButtons .btn {
        font-size: 14px;
        padding: 0px 18px;
        height: 43px;
    }
}

/* 480px End */
@media screen and (max-width: 560px) {
    .personDetailHeaderRowDoubleRow {
        flex-direction: column;
    }

    .personDetailHeaderRowDoubleRow .lessonBox {
        padding-left: 0px;
    }

    .personDetailHeaderRowDoubleRow .phoneBox {
        padding-right: 0px;
        border-right: none;
    }
}
@media screen and (max-width: 700px) {
    .educationRatingStudentDetailButtonBar.default-flex {
        flex-wrap: wrap;
    }
    .overviewInfoHighlight.grayText.default-inline-flex {
        width: 100%;
        justify-content: space-between;
        padding-right: 0px;
    }
    .default-flex.lessonActionButtons {
        padding-right: 10px;
    }
}
/* Orientation Landscape*/
@media screen and (orientation: landscape) {
    .introductionPhoto img {
        max-width: 20%;
    }

    .introductionPhoto-img-wrapper {
        margin-bottom: 20px;
    }

    .introductionPhoto .veryLargeTitle {
        margin-bottom: 20px;
    }
}

@media screen and (orientation: landscape) and (max-width: 768px) {
    .introductionPhoto .veryLargeTitle {
        font-size: 30px;
    }
}

@media screen and (max-width: 800px) {
    #checklistViewEducationCardListView .tableCell {
        display: inline-block;
        height: auto;
        border-bottom: none;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    #checklistViewEducationCardListView .educationCardLeft {
        width: 100%;
    }

    #checklistViewEducationCardListView .educationCardRight {
        padding-left: 0;
        width: auto;
    }

    #checklistViewEducationCardListView .km-list > li {
        display: block;
        border-bottom: 1px solid white;
        margin-right: 0;
        margin-left: 0;
    }

    #checklistViewEducationCardListView .km-list > li:last-child {
        border-bottom: none;
    }
}
@media screen and (max-width: 354px) {
    .overviewButtons.default-flex {
        justify-content: flex-end;
        flex-wrap: wrap;
    }
    #completedDrivingLessonsMasterElement .tableCell.smallText {
        font-size: 10px;
    }
}
